<template>
  <div class="home-body home-4 about-page">
    <div class="wrapper">
      <!-- nav header -->
      <header class="header-area stick-h2">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="logo floatleft">
                <a href="/">
                  <img
                    alt="GiveProudly - logo"
                    src="../assets/logo/giveProudly-log.svg"
                  />
                </a>
              </div>
              <div class="main-menu floatright">
                <nav>
                  <ul>
                    <li>
                      <a href="/" class="remove-link">HOME</a>
                    </li>
                    <li class="active remove-link">
                      <a href="/about">ABOUT</a>
                    </li>
                    <li>
                      <a href="/faq" class="remove-link">FAQ</a>
                    </li>
                    <li>
                      <a href="/contact" class="remove-link">CONTACT</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!-- mobile nav menu -->
      <div class="mobile-menu-area d-block d-lg-none">
        <div class="container">
          <div class="mobile-menu-area">
            <div class="row">
              <div class="col-md-12">
                <div class="mobile-menu text-center py-3">
                  <nav>
                    <a class="remove-link mr-3" href="/">HOME </a>
                    <a class="active remove-link mr-3" href="/about">ABOUT </a>
                    <a class="remove-link mr-3" href="/faq">FAQ </a>
                    <a class="remove-link" href="/contact">CONTACT </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- mobile-menu-area end -->
      <!-- hero area -->
      <section class="slider-main-area">
        <div class="main-slider an-si">
          <div class="bend niceties preview-2">
            <!-- <div id="ensign-nivoslider-2" class="slides"> -->
            <div class="hero-slides-faq">
              <img
                src="../assets/img/secondary-main.jpg"
                class="main-image"
                alt=""
                title="#slider-direction-1"
              />
              <div class="main-container">
                <div class="inner-container container-xl">
                  <div class="slide-title">
                    <div class="section-title-about">
                      <h1 class="title6">ABOUT US</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- what we do section -->
      <section class="what-area section-margin ptb-80">
        <div class="container">
          <!-- <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="what-top">
                            <div class="section-title">
                                <h1 class="main-heading mb-0">WHO WE ARE</h1>
                            </div>
                        </div>
                    </div>
                </div> -->
          <div class="row justify-content-center">
            <div class="col-md col-sm text-center">
              <p class="about-paragraph mb-150" id="aboutText"></p>
              <div
                id="apiLoader"
                class="d-flex flex-row align-items-center justify-content-center mx-auto w-100 my-5"
              >
                <div class="loader"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- footer section -->
      <footer id="footer" class="social-area">
        <div class="footer-top">
          <div class="container-lg">
            <div class="row">
              <div
                class="d-flex flex-row align-items-center justify-content-start col-lg-6 col-md-6 col-sm-6 col-12"
              >
                <h4 class="social-follow-title">Follow Us</h4>
              </div>
              <div
                class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-lg-6 col-md-6 col-sm-6 col-12"
              >
                <div class="social-icon floatright">
                  <ul>
                    <li>
                      <a
                        class="twitter"
                        target="_blank"
                        href="https://twitter.com/YourPersonalPAC"
                      >
                        <i class="fa fa-twitter-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="facebook"
                        target="_blank"
                        href="https://www.facebook.com/ThePersonalPACProject"
                      >
                        <i class="fa fa-facebook-square" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        class="youtube"
                        target="_blank"
                        href="https://www.youtube.com/channel/UCAuoNhsEtra6ACv8leeEexg"
                      >
                        <i class="fa fa-youtube-square" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-middle ptb-40">
          <div class="container-lg">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="middle-text">
                  <div class="social-logo">
                    <a href="/">
                      <img
                        src="../assets/logo/giveproudly-logo-white.png"
                        alt=""
                        class="logo-size"
                      />
                    </a>
                  </div>
                  <div class="middle-mgn"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="container-lg">
            <div class="footer-bottom-all">
              <div class="row">
                <div
                  class="d-flex flex-row align-items-center justify-content-center justify-content-sm-start col-12 col-sm-7 col-lg-6"
                >
                  <div class="footer-menu">
                    <ul>
                      <li>
                        <a href="/" class="remove-link">HOME</a>
                      </li>
                      <li>
                        <a href="/about" class="active remove-link">ABOUT</a>
                      </li>
                      <li>
                        <a href="/faq" class="remove-link">FAQ</a>
                      </li>
                      <li>
                        <a href="/contact" class="remove-link">CONTACT</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end col-12 col-sm-5 col-lg-6"
                >
                  <div class="footer-menu right-side-menu">
                    <ul>
                      <li>
                        <a href="/privacy" class="remove-link">PRIVACY</a>
                      </li>
                      <li>
                        <a href="/terms" class="remove-link">T&amp;C</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<!-- jquery -->
<script src="js/vendor/jquery-1.12.4.min.js"></script>
<!-- Popper JS -->
<script src="js/popper.js"></script>
<!-- bootstrap JS -->
<script src="js/bootstrap.min.js"></script>
<!-- wow JS -->
<script src="js/wow.min.js"></script>
<!-- plugins JS -->
<script src="js/plugins.js"></script>
<!-- main JS -->
<script src="js/main.js"></script>
<!-- modernizr JS -->
<script src="js/vendor/modernizr-3.11.7.min.js"></script>
<script>
import "@/assets/styles/give-proudly/style.css";

// import "@/assets/styles/style.css";
import "@/assets/styles/animate.css";
import "@/assets/styles/bootstrap.min.css";
import "@/assets/styles/font-awesome.min.css";
import "@/assets/styles/magnific-popup.css";
import "@/assets/styles/main.css";
import "@/assets/styles/meanmenu.min.css";
import "@/assets/styles/normalize.css";
import "@/assets/styles/responsive.css";
// import "@/assets/styles/color-custom.css";
import "@/assets/styles/give-proudly/color-custom.css";

export default {
  data() {
    return {
      env: null,
    };
  },
  mounted() {
    this.env = process.env.VUE_APP_NAME;
  },
};

const aboutTextUrl = `${process.env.VUE_APP_API_URL}/content/about`;

fetch(aboutTextUrl)
  .then((resp) => resp.json())
  .then(function (data) {
    if (data.code == 200 && data.message == "success") {
      // API result
      let result = data.content;
      document.getElementById("aboutText").innerHTML = result.about;
      console.log(result.about);
      // hide loader after API is processed
      document.getElementById("apiLoader").className = "d-none";
    } else {
      console.log("Unable to load About Content", data.details);
    }
  });
</script>
<style scoped>
.logo-size {
  object-fit: cover;
  height: 200px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.remove-link {
  text-decoration: none;
  color: white;
  border: none !important;
}
.mobile-menu {
  height: 60px;
}
.active {
  color: black;
  text-decoration: underline;
}

.about-paragraph {
  text-align: left;
}
.about-paragraph >>> .ql-align-right {
  text-align: right;
}

.about-paragraph >>> .ql-align-left {
  text-align: left;
}

.about-paragraph >>> .ql-align-center {
  text-align: center;
}

.about-paragraph >>> .ql-align-justify {
  text-align: justify;
}

.about-paragraph >>> ol {
  list-style: numbers !important;
  text-align: left;
}

.about-paragraph >>> ul {
  list-style: disc !important;
  text-align: left;
}
</style>
