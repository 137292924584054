<template>
  <!-- <InitLayout> -->
  <!-- logo placeholder -->
  <b-container class="align">
    <b-row class="justify-content-center" no-gutters>
      <img
        alt="Personal PAC"
        src="@/assets/logo/personalpac-logo.svg"
        class="logo-img"
      />
    </b-row>
    <b-row class="justify-content-center">
      <span class="errorCode">404</span>
    </b-row>
    <b-row class="justify-content-center">
      <span class="error">Page not found</span>
    </b-row>
    <b-row class="justify-content-center pt-3">
      <span class="errorDis"
        >Whoops...We can't seem to find the page you are looking for.</span
      >
    </b-row>
  </b-container>
  <!-- </InitLayout> -->
</template>

<script>
// services

// components
// @ is an alias to /src
// import InitLayout from "@/layout/InitLayout";

export default {
  name: "Error404",
  components: {
    // InitLayout,
  },
  data() {
    return {
      isAPILoading: false,
    };
  },
  watch: {},
  methods: {},
  mounted() {
    document.getElementById("backgroundImgId").style.background =
      "url(" +
      "https://images.unsplash.com/photo-1561084711-b2e525f5a4c0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80";
    (")");
    document.getElementById("backgroundImgId").style.backgroundSize = "cover";
  },
};
</script>

<style scoped>
.errorCode {
  width: 193px;
  height: 110px;

  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 112px;
  text-align: center;
  color: #3c3b6e;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.error {
  width: 374px;
  height: 47px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #3c3b6e;
  flex: none;
  order: 2;
  flex-grow: 0;
}
.errorDis {
  width: 367px;
  height: 68px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #9fa2b4;
  flex: none;
  order: 3;
  flex-grow: 0;
}
.logo-img {
  width: 150px;
  height: 53.96px;
}
.align {
  margin-left: 26%;
  padding-top: 20%;
}
@media (max-width: 991.98px) {
  .align {
    margin-left: 3%;
    padding-top: 15%;
  }
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .align {
    margin-left: 0%;
    padding-top: 10%;
  }
  .errorCode {
    font-size: 35px;
    margin-top: -35px;
  }
  .errorDis {
    font-size: 12px;
  }
  .error {
    font-size: 20px;
    margin-top: -30px;
  }
  .logo-img {
    width: 90px;
    height: 53.96px;
  }
}
</style>
